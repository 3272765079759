import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CanceledError } from 'axios'
import CommonShippingFees from 'common/components/entities/ShippingFees'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { mockedPhysicalProduct } from 'common/mocks/product'
import { mockedShippingResponse } from 'common/mocks/shipping'
import {
  SelectedShipping,
  ShippingFeesInterface,
} from 'common/types/entities/ShippingFeesInterface'
import { ShippingResponse } from 'common/types/shippingType'
import { getShippingRules } from 'publisher/api/shippingApi'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import {
  managementSelectors,
  useManagement,
  useOptIn,
  usePage,
  usePayment,
} from 'publisher/store'
import {
  getCustomerCountry,
  getFieldValueBySlug,
} from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import { setSelectedShipping } from 'publisher/store/payment/paymentActions'
import paymentSelectors, {
  getCheckedBump,
  getSelectedShipping,
} from 'publisher/store/payment/paymentSelectors'

interface ShippingFeesProps {
  entity: ShippingFeesInterface
}

const ShippingFees = ({ entity }: ShippingFeesProps) => {
  const dispatch = useDispatch()
  let product = usePayment(paymentSelectors.getProduct)
  const productVariant = usePayment(paymentSelectors.getProductActiveVariant)
  const bumpProduct = usePayment(getCheckedBump)
  const coupon = usePayment(paymentSelectors.getCheckedCoupon)
  const quantity = usePayment(paymentSelectors.getProductQuantity)
  const customerCountry = useOptIn(
    state =>
      getFieldValueBySlug(state, FieldSlugEnum.Country) ||
      getCustomerCountry(state),
  )

  const isScreenshotMode = useManagement(managementSelectors.isScreenshotMode)
  const isSsr = typeof window === 'undefined'
  const isPreview = useManagement(managementSelectors.isPreview)
  const isTemplate = usePage(pageSelectors.getIsPageTemplate)
  const isUsingMockedData =
    isTemplate || (!product && isPreview) || isScreenshotMode

  const [isLoading, setIsLoading] = useState(false)
  const [abortController, setAbortController] = useState<AbortController>()
  const [shippingData, setShippingData] = useState<
    ShippingResponse | undefined
  >(isUsingMockedData ? mockedShippingResponse : undefined)
  const selectedShipping = usePayment(getSelectedShipping)
  const handleShippingSelect = async (shipping: SelectedShipping) => {
    dispatch(setSelectedShipping(shipping))
  }

  if (isUsingMockedData) {
    product = mockedPhysicalProduct
  }

  useEffect(() => {
    if (isUsingMockedData || !product || !customerCountry) {
      return
    }

    const fetchShipping = async () => {
      try {
        dispatch(setSelectedShipping(null))
        setIsLoading(true)
        const controller = new AbortController()
        setAbortController(controller)

        const { data } = await getShippingRules(
          {
            country: customerCountry,
            product: product.id,
            productVariant: productVariant?.id,
            coupon: coupon?.id,
            bumpProduct: bumpProduct?.product?.id,
            quantity,
          },
          controller.signal,
        )

        setShippingData(data)
        setIsLoading(false)
      } catch (err) {
        if (err instanceof CanceledError) {
          // do nothing
        } else {
          setIsLoading(false)
        }
      }
    }

    if (abortController?.signal) {
      abortController.abort('new_request_sent')
    }

    fetchShipping()
  }, [
    customerCountry,
    product?.id,
    coupon?.id,
    bumpProduct?.product?.id,
    productVariant,
    quantity,
  ])

  if ((isSsr && !isScreenshotMode) || !product) {
    return (
      <StyleWrapper
        margin={entity.margin}
        appearance={entity.appearance}
        mobileMargin={entity.mobileMargin}
      />
    )
  }

  return (
    <StyleWrapper
      margin={entity.margin}
      mobileMargin={entity.mobileMargin}
      appearance={entity.appearance}
    >
      <CommonShippingFees
        isLoading={isLoading}
        selectedShipping={selectedShipping}
        customerCountry={customerCountry}
        onChange={handleShippingSelect}
        entity={entity}
        shipping={shippingData}
      />
    </StyleWrapper>
  )
}

export default ShippingFees
