import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { XenditFieldTypeEnum } from 'publisher/enums/XenditFieldTypeEnum'
import { usePayment } from 'publisher/store'
import paymentSelectors, {
  getActivePricePlan,
  getOfferPricing,
  getPaymentMethods,
  getXenditPublicKey,
} from 'publisher/store/payment/paymentSelectors'
import loadScript from 'publisher/utils/loadScript'
import { centsToEuro } from 'common/utils/priceCalculator'

export type Xendit = {
  setPublishableKey: (publicApiKey: string) => void
  card: {
    validateCardNumber: (cardNumber: string) => boolean
    validateExpiry: (month: string, year: string) => boolean
    validateCvn: (cvn: string) => boolean
    createToken: (value1: any, value2: any) => void
    createAuthentication: (value1: any, value2: any) => void
  }
}

interface XenditFieldsInterface {
  month: string
  year: string
  cardNumber: string
  cardHolderName: string
  cvv: string
  amount?: number
}

interface XenditErrorInterface {
  cardNumber: string
  cardCvn: string
  expiryDate: string
}

export type XenditWrapper = {
  fields: XenditFieldsInterface
  changeFields: Record<string, Dispatch<SetStateAction<string>>>
  handleXenditVerification: (
    type: XenditFieldTypeEnum,
    value: string,
    value2?: string,
  ) => boolean
  errors: XenditErrorInterface
  resetError: (key: keyof XenditErrorInterface, value: string) => void
  isSecureModalVisible: boolean
  setIsSecureModalVisible: React.Dispatch<React.SetStateAction<boolean>>
}

declare global {
  interface Window {
    Xendit: Xendit
  }
}

const defaultErrors: XenditErrorInterface = {
  cardNumber: '',
  cardCvn: '',
  expiryDate: '',
}

const XenditContext = createContext<XenditWrapper>({} as XenditWrapper)

export const useXendit = () => useContext(XenditContext)

export function XenditProvider(
  props: React.PropsWithChildren<Record<string, never>>,
) {
  const { t } = useTranslation('publisher')
  const paymentMethods = usePayment(getPaymentMethods)
  const xenditPublicKey = usePayment(getXenditPublicKey)
  const [errors, setErrors] = useState(defaultErrors)
  const [isSecureModalVisible, setIsSecureModalVisible] = useState(false)

  const product = usePayment(paymentSelectors.getProduct)
  const pricePlan = usePayment(getActivePricePlan)

  const offerPricing = usePayment(getOfferPricing)

  // either product or price plan depending on offer
  const selectedPricing = offerPricing?.find(
    ({ productId, pricePlanId }) =>
      productId === product?.id && pricePlanId === pricePlan?.id,
  )
  const paymentAmount = centsToEuro(selectedPricing?.grossAmount ?? 0)

  const [month, setMonth] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [cardHolderName, setCardHolderName] = useState('')
  const [year, setYear] = useState('')
  const [cvv, setCvv] = useState('')

  useEffect(() => {
    if (
      [PaymentMethodEnum.XenditIdCard, PaymentMethodEnum.XenditPhCard].some(
        paymentMethod => paymentMethods.includes(paymentMethod),
      ) &&
      xenditPublicKey
    ) {
      loadScript(process.env.XENDIT_API_URL as string, 'xendit', async () => {
        window.Xendit.setPublishableKey(xenditPublicKey)
      })
    }
  }, [paymentMethods, xenditPublicKey])

  function addError(key: keyof XenditErrorInterface, message: string) {
    setErrors(errors => ({
      ...errors,
      [key]: message,
    }))
  }
  function resetError(key: keyof XenditErrorInterface) {
    setErrors(errors => ({
      ...errors,
      [key]: '',
    }))
  }

  function handleXenditVerification(
    type: XenditFieldTypeEnum,
    value: string,
    value2 = '',
  ) {
    if (type === XenditFieldTypeEnum.card_number) {
      if (!window.Xendit.card.validateCardNumber(value)) {
        addError(
          'cardNumber',
          t('components.payment_methods.xendit.invalid_card_number'),
        )
        return false
      }
    } else if (type === XenditFieldTypeEnum.expiry_date) {
      if (!window.Xendit.card.validateExpiry(value, value2)) {
        addError(
          'expiryDate',
          t('components.payment_methods.xendit.invalid_card_expiration_date'),
        )
        return false
      }
    } else if (type === XenditFieldTypeEnum.cvn_number) {
      if (!window.Xendit.card.validateCvn(value)) {
        addError(
          'cardCvn',
          t('components.payment_methods.xendit.invalid_cvn_number'),
        )
        return false
      }
    }

    return true
  }

  return (
    <XenditContext.Provider
      value={{
        fields: {
          month: month,
          year: year,
          cardNumber: cardNumber,
          cardHolderName: cardHolderName,
          cvv: cvv,
          amount: paymentAmount,
        },
        changeFields: {
          onChangeMonth: setMonth,
          onChangeYear: setYear,
          onChangeCardNumber: setCardNumber,
          onChangeCardHolderName: setCardHolderName,
          onChangeCvv: setCvv,
        },
        handleXenditVerification,
        errors,
        resetError,
        isSecureModalVisible,
        setIsSecureModalVisible,
      }}
    >
      <form id="form-checkout">{props.children}</form>
    </XenditContext.Provider>
  )
}
