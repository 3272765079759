import axios from 'axios'
import {
  CalculatedPrice,
  CalculatePriceData,
  GetShippingRules,
  ShippingResponse,
} from 'common/types/shippingType'

export const getShippingRules = (
  data: GetShippingRules,
  abortSignal?: AbortSignal,
) =>
  axios.post<ShippingResponse>('/api/payment/shipping-rules', data, {
    signal: abortSignal,
  })

export const calculatePrice = (
  data: CalculatePriceData[],
  abortSignal?: AbortSignal,
) =>
  axios.post<CalculatedPrice[]>('/api/payment/calculate-price', data, {
    signal: abortSignal,
  })
