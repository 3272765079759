export const parseToObject = (stringColor: string) => {
  const rgbaArray = stringColor.split('(')[1].split(')')[0].split(',')

  return {
    r: +rgbaArray[0],
    g: +rgbaArray[1],
    b: +rgbaArray[2],
    a: +rgbaArray[3],
  }
}

export interface RGBColor {
  a?: number
  b: number
  g: number
  r: number
}

export const getColorAsString = (color: RGBColor) =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`

export const isRgba = (style: string) => new RegExp('^rgba').test(style)

export const changeStringColorOpacity = (
  stringColor: string,
  opacity: number,
) => {
  const colorObj = parseToObject(stringColor)
  colorObj.a = opacity
  return getColorAsString(colorObj)
}

export function isNotTransparent(color: string) {
  const { a } = parseToObject(color)

  return Boolean(a)
}

export function isTransparent(color: string) {
  const { a } = parseToObject(color)

  return !a
}

export function RGBAToHexA(rgba: string) {
  const obj = parseToObject(rgba)
  let r = obj.r.toString(16)
  let g = obj.g.toString(16)
  let b = obj.b.toString(16)
  // let a = Math.round(obj.a * 255).toString(16)

  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b
  // if (a.length === 1) a = '0' + a

  // return '#' + r + g + b + a
  return '#' + r + g + b
}
