import { OfferPricing, ProductVariantInterface } from 'common/types/entities/ProductInterface'
import {
  DECREASE_PRODUCT_QUANTITY,
  INCREASE_PRODUCT_QUANTITY,
  INITIALIZE_PRODUCT_QUANTITY,
  SET_ACTIVE_PRICE_PLAN_ID,
  SET_ACTIVE_PRODUCT_VARIANT,
  SET_DLOCAL_DOCUMENT_NUMBER,
  SET_OFFER_PRICING,
  SET_SELECTED_SHIPPING,
} from './paymentActionTypes'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'

export function setDlocalDocumentNumber(value: string) {
  return {
    payload: value,
    type: SET_DLOCAL_DOCUMENT_NUMBER,
  }
}

export function setActivePricePlanId(pricePlanId: number) {
  return {
    payload: pricePlanId,
    type: SET_ACTIVE_PRICE_PLAN_ID,
  }
}

export function initializeProductQuantity(quantity: number) {
  return {
    payload: quantity,
    type: INITIALIZE_PRODUCT_QUANTITY,
  }
}

export function increaseProductQuantity() {
  return {
    type: INCREASE_PRODUCT_QUANTITY,
  }
}

export function decreaseProductQuantity() {
  return {
    type: DECREASE_PRODUCT_QUANTITY,
  }
}

export function setProductActiveVariant(
  productVariant: ProductVariantInterface,
) {
  return {
    type: SET_ACTIVE_PRODUCT_VARIANT,
    payload: productVariant,
  }
}

export const setOfferPricing = (offerPricing: OfferPricing[]) => {
  return {
    type: SET_OFFER_PRICING,
    payload: offerPricing,
  }
}

export function setSelectedShipping(selectedShipping: SelectedShipping | null) {
  return {
    type: SET_SELECTED_SHIPPING,
    payload: selectedShipping,
  }
}
