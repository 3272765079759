import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { Coupon as CouponType } from 'common/types/CouponType'
import { PricePlan as PricePlanType } from 'common/types/OfferInterface'
import {
  OfferPricing,
  ProductInterface,
} from 'common/types/entities/ProductInterface'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import DividerUi from '../ui/DividerUi'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemUi from '../ui/PricePlanItemUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductBumpTotalProps {
  activePricePlan?: PricePlanType
  quantity?: number
  coupon?: CouponType
  productBump: ProductInterface
  activePlanPricing?: OfferPricing
  mainOfferPricing?: OfferPricing
  bumpOfferPricing?: OfferPricing
  isFinalSummary?: boolean
  locale: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

const ProductBumpTotal = ({
  activePricePlan,
  activePlanPricing,
  mainOfferPricing,
  bumpOfferPricing,
  productBump,
  isFinalSummary = true,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: ProductBumpTotalProps) => {
  const { t } = useTranslation(['common', 'publisher'])

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemUi skipLeftOffset={!activePricePlan}>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
            data-test-id={`offer-price-product-bump-total-${productBump.name}`}
          >
            {isFinalSummary
              ? t(
                  'components.offer_price.one_shots_summary.total_amount_with_tax',
                )
              : t(
                  'components.offer_price.one_shots_summary.subtotal_amount_with_tax',
                )}
          </PricePlanDescriptionUi>
          {activePricePlan ? (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {activePricePlan.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                centsToEuro(activePlanPricing?.grossAmount ?? 0) +
                  centsToEuro(bumpOfferPricing?.grossAmount ?? 0),
                activePricePlan.currency,
                locale,
              )}
            </PricePlanAmountUi>
          ) : (
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {productBump.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                centsToEuro(mainOfferPricing?.grossAmount ?? 0) +
                  centsToEuro(bumpOfferPricing?.grossAmount ?? 0),
                productBump.currency,
                locale,
              )}
            </PricePlanAmountUi>
          )}
        </PricePlanItemUi>
      </PricePlanUi>
    </>
  )
}

export default ProductBumpTotal
