import {
  Stripe,
  StripeExpressCheckoutElementConfirmEvent,
  StripeExpressCheckoutElementOptions,
} from '@stripe/stripe-js'
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { BadRequest, InternalError, NetworkError } from 'common/errors'
import { optInFail } from 'publisher/actions/optInActions'
import { buyMainOffer, confirmPaymentIntent } from 'publisher/api/stripe'
import { IntentTypeEnum } from 'publisher/enums/IntentTypeEnum'
import usePaymentSubmit from 'publisher/hooks/usePaymentSubmit'
import CreditCardRowUi from 'publisher/pages/offer-page/components/PaymentMethod/PaymentMethodDetails/shared/ui/CreditCardRowUi'
import { pageSelectors, usePage, usePayment } from 'publisher/store'
import paymentSelectors from 'publisher/store/payment/paymentSelectors'

const getStripeConfirmHandler = (stripe: Stripe, type: IntentTypeEnum) =>
  type === IntentTypeEnum.setup
    ? stripe.confirmCardSetup
    : stripe.confirmCardPayment

const ExpressCheckout = () => {
  const pageId = usePage(pageSelectors.getPageId)
  const purchaseProcessId = usePayment(paymentSelectors.getPurchaseProcessId)
  const dispatch = useDispatch()
  const entity = usePage(pageSelectors.getRootEntity)
  const { t } = useTranslation()
  const { setErrors, submit } = usePaymentSubmit(entity)
  const stripe = useStripe()
  const elements = useElements()

  const options: StripeExpressCheckoutElementOptions = {
    paymentMethods: {
      applePay: 'always',
      googlePay: 'never',
      amazonPay: 'never',
      link: 'never',
      paypal: 'never',
    },
  }

  const handleConfirm = async (
    event: StripeExpressCheckoutElementConfirmEvent,
  ) => {
    submit(async body => {
      if (!stripe || !elements) return

      const { error: submitError } = await elements.submit()

      if (submitError) {
        event.paymentFailed()
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
      })

      if (error || !paymentMethod) {
        event.paymentFailed()
        return
      }

      try {
        const { data: mainOfferData } = await buyMainOffer(
          pageId,
          purchaseProcessId,
          {
            payment_form: {
              ...body,
              paymentMethodId: paymentMethod.id,
            },
          },
        )

        if (mainOfferData.intentSecret) {
          const mainOfferStripeConfirmHandler = getStripeConfirmHandler(
            stripe,
            mainOfferData.intentType,
          )

          const confirmCardPaymentData = mainOfferData.paymentMethodId
            ? { payment_method: mainOfferData.paymentMethodId }
            : undefined

          const { error } = await mainOfferStripeConfirmHandler(
            mainOfferData.intentSecret,
            confirmCardPaymentData,
          )

          if (!error) {
            const { data: bumpData } = await confirmPaymentIntent(
              pageId,
              purchaseProcessId,
            )
            // bump case
            if (bumpData.intentSecret) {
              const bumpOfferStripeConfirmHandler = getStripeConfirmHandler(
                stripe,
                bumpData.intentType,
              )

              const { error } = await bumpOfferStripeConfirmHandler(
                bumpData.intentSecret,
                { payment_method: bumpData.paymentMethodId },
              )

              if (!error) {
                const { data } = await confirmPaymentIntent(
                  pageId,
                  purchaseProcessId,
                )
                window.location.assign(data.redirect)
                return
              }
            }

            // redirect to upSell or thank you page
            window.location.assign(mainOfferData.redirect)
          } else {
            setErrors([error.message as string])
          }
        } else {
          // redirect to upSell or thank you page
          window.location.href = mainOfferData.redirect
        }
      } catch (error) {
        event.paymentFailed({ reason: 'fail' })
        if (error instanceof BadRequest) {
          setErrors(error.response.data.errors.common)
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        } else if (error instanceof NetworkError) {
          setErrors([t('core.errors.no_connection')])
        } else if (error instanceof InternalError) {
          setErrors([t('core.error.title')])
        }
      }
    })
  }

  return (
    <CreditCardRowUi flexDirectionColumn>
      <ExpressCheckoutElement
        className={'StripeElement__express'}
        onConfirm={handleConfirm}
        options={options}
      />
    </CreditCardRowUi>
  )
}

export default ExpressCheckout
