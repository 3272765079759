import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import { CouponTypeEnum } from 'common/enums/CouponTypeEnum'
import { Coupon } from 'common/types/CouponType'
import { CalculatedPriceInfo } from 'common/types/OfferInterface'
import { ProductInterface } from 'common/types/entities/ProductInterface'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'
import { getLocale } from 'client/reducers/pageReducer'
import { usePage } from 'publisher/store'
import PricePlanAmountUi from '../ui/PricePlanAmountUi'
import PricePlanDescriptionUi from '../ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from '../ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanNameUi from '../ui/PricePlanNameUi'
import PricePlanUi from '../ui/PricePlanUi'

interface ProductProps extends CalculatedPriceInfo {
  product?: ProductInterface | null
  coupon?: Coupon
  isFinalSummary?: boolean
  nameFontFamily?: string
  mobileNameFontFamily?: string
  nameFontSize: number
  mobileNameFontSize?: number
  nameFontStyle?: string
  mobileNameFontStyle?: string
  nameFontWeight?: string
  mobileNameFontWeight?: string
  nameLineHeight: number
  mobileNameLineHeight?: number
  nameColor: string
  mobileNameColor?: string
  amountFontFamily?: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

const Product = ({
  netAmount = 0,
  grossAmount = 0,
  taxAmount = 0,
  taxPercent = 0,
  isFinalSummary = true,
  product,
  coupon,
  nameFontSize,
  mobileNameFontSize,
  nameFontStyle,
  mobileNameFontStyle,
  nameFontWeight,
  mobileNameFontWeight,
  nameFontFamily,
  mobileNameFontFamily,
  nameLineHeight,
  mobileNameLineHeight,
  nameColor,
  mobileNameColor,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: ProductProps) => {
  const { t } = useTranslation(['common', 'publisher'])
  const locale = usePage(getLocale)
  const needTotal = product && product.name && (taxAmount || coupon)

  return (
    <PricePlanUi>
      {product && product.name && (
        <>
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanNameUi
              fontSize={nameFontSize}
              mobileFontSize={mobileNameFontSize}
              fontStyle={nameFontStyle}
              mobileFontStyle={mobileNameFontStyle}
              fontWeight={nameFontWeight}
              mobileFontWeight={mobileNameFontWeight}
              fontFamily={nameFontFamily}
              mobileFontFamily={mobileNameFontFamily}
              lineHeight={nameLineHeight}
              mobileLineHeight={mobileNameLineHeight}
              color={nameColor}
              mobileColor={mobileNameColor}
            >
              {product.name}
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
                mobileColor={mobileDescriptionColor}
              >
                {/* {product.variantDescription} */}
                {product.description}
              </PricePlanDescriptionUi>
            </PricePlanNameUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {product.currency}
              </CurrencyBadgeUi>
              {getReadablePrice(
                centsToEuro(netAmount),
                product.currency,
                locale,
              )}
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
          {coupon && (
            <PricePlanItemWithoutLeftOffsetUi>
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
                mobileColor={mobileDescriptionColor}
                data-test-id={`offer-price-product-${product.name}`}
              >
                {t('components.offer_price.one_shots_summary.discount')}
                {coupon.discountType === CouponTypeEnum.Percent &&
                  ` (-${coupon.discountAmount}%)`}
              </PricePlanDescriptionUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {coupon.currency}
                </CurrencyBadgeUi>
                {` -${getReadablePrice(
                  coupon.discountType === CouponTypeEnum.Fixed
                    ? centsToEuro(coupon.discountAmount)
                    : // percentage to currency value conversion
                      centsToEuro(netAmount * (coupon.discountAmount / 100)),
                  coupon.currency,
                  locale,
                )}`}
              </PricePlanAmountUi>
            </PricePlanItemWithoutLeftOffsetUi>
          )}
          {!!taxAmount && (
            <PricePlanItemWithoutLeftOffsetUi>
              <PricePlanDescriptionUi
                fontSize={descriptionFontSize}
                mobileFontSize={mobileDescriptionFontSize}
                fontStyle={descriptionFontStyle}
                mobileFontStyle={mobileDescriptionFontStyle}
                fontWeight={descriptionFontWeight}
                mobileFontWeight={mobileDescriptionFontWeight}
                fontFamily={descriptionFontFamily}
                mobileFontFamily={mobileDescriptionFontFamily}
                lineHeight={descriptionLineHeight}
                mobileLineHeight={mobileDescriptionLineHeight}
                color={descriptionColor}
                mobileColor={mobileDescriptionColor}
              >
                {`${t('components.offer_price.vat')} (${taxPercent}%)`}
              </PricePlanDescriptionUi>
              <PricePlanAmountUi
                fontSize={amountFontSize}
                mobileFontSize={mobileAmountFontSize}
                fontStyle={amountFontStyle}
                mobileFontStyle={mobileAmountFontStyle}
                fontWeight={amountFontWeight}
                mobileFontWeight={mobileAmountFontWeight}
                fontFamily={amountFontFamily}
                mobileFontFamily={mobileAmountFontFamily}
                lineHeight={amountLineHeight}
                mobileLineHeight={mobileAmountLineHeight}
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {product.currency}
                </CurrencyBadgeUi>
                {getReadablePrice(
                  centsToEuro(taxAmount),
                  product.currency,
                  locale,
                )}
              </PricePlanAmountUi>
            </PricePlanItemWithoutLeftOffsetUi>
          )}
        </>
      )}
      {!!needTotal && (
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
          >
            {isFinalSummary
              ? t('components.offer_price.total')
              : t('components.offer_price.subtotal')}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <CurrencyBadgeUi
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              {product.currency}
            </CurrencyBadgeUi>
            {getReadablePrice(
              centsToEuro(grossAmount),
              product.currency,
              locale,
            )}
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
      )}
    </PricePlanUi>
  )
}
export default Product
