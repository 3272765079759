import PropTypes from 'prop-types'
import React from 'react'
import { buttonActionsTypes } from 'common/constants/settings'
import { useDelay } from 'publisher/hooks/useDelay'
import { ButtonActionTypeEnum } from '../../../../common/enums/ButtonActionTypeEnum'
import ContactUsButton from '../../../pages/contact-us/components/ContactUsButton'
import CustomLoginButton from '../CustomLoginButton'
import BlogLinkButton from './BlogLinkButton'
import DownloadFileButton from './DownloadFileButton'
import NextStepRedirectionButton from './NextStepRedirectionButton'
import OpenUrlButton from './OpenUrlButton'
import SendFormButton from './SendFormButton'
import ShowPopupButton from './ShowPopupButton'

function OptInButton({ entity }) {
  const isVisible = useDelay(entity.options.delay)

  if (!isVisible) {
    return <div />
  }

  switch (entity.options.action) {
    case buttonActionsTypes.nextStepRedirection:
      return <NextStepRedirectionButton entity={entity} />
    case buttonActionsTypes.openUrl:
      return <OpenUrlButton entity={entity} />
    case buttonActionsTypes.downloadFile:
      return <DownloadFileButton entity={entity} />
    case buttonActionsTypes.sendForm:
      return <SendFormButton entity={entity} />
    case buttonActionsTypes.showPopup:
      return <ShowPopupButton entity={entity} />
    case buttonActionsTypes.blogLink:
      return <BlogLinkButton entity={entity} />
    case buttonActionsTypes.login:
    case buttonActionsTypes.resetPassword:
    case buttonActionsTypes.confirmRegistration:
      return <CustomLoginButton entity={entity} />
    case ButtonActionTypeEnum.ContactUs:
      return <ContactUsButton entity={entity} />
    default:
      throw new Error(`Unknown action type ${entity.options.action}`)
  }
}

OptInButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      action: PropTypes.oneOf(Object.values(buttonActionsTypes)),
      delay: PropTypes.number,
    }),
  }).isRequired,
}

export default OptInButton
