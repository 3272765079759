import React from 'react'
import { useTranslation } from 'react-i18next'
import CurrencyBadgeUi from 'common/components/entities/OfferPriceNew/ui/CurrencyBadgeUi'
import DividerUi from 'common/components/entities/OfferPriceNew/ui/DividerUi'
import PricePlanAmountUi from 'common/components/entities/OfferPriceNew/ui/PricePlanAmountUi'
import PricePlanDescriptionUi from 'common/components/entities/OfferPriceNew/ui/PricePlanDescriptionUi'
import PricePlanItemWithoutLeftOffsetUi from 'common/components/entities/OfferPriceNew/ui/PricePlanItemWithoutLeftOffsetUi'
import PricePlanUi from 'common/components/entities/OfferPriceNew/ui/PricePlanUi'
import { OfferPricing } from 'common/types/entities/ProductInterface'
import { SelectedShipping } from 'common/types/entities/ShippingFeesInterface'
import { centsToEuro, getReadablePrice } from 'common/utils/priceCalculator'

interface ShippingSummaryProps {
  productPricing?: OfferPricing
  bumpOfferPricing?: OfferPricing
  selectedShipping: SelectedShipping
  amountFontFamily?: string
  locale: string
  mobileAmountFontFamily?: string
  amountFontSize: number
  mobileAmountFontSize?: number
  amountFontStyle?: string
  mobileAmountFontStyle?: string
  amountFontWeight?: string
  mobileAmountFontWeight?: string
  amountLineHeight: number
  mobileAmountLineHeight?: number
  amountColor: string
  mobileAmountColor?: string
  descriptionFontFamily?: string
  mobileDescriptionFontFamily?: string
  descriptionFontSize: number
  mobileDescriptionFontSize?: number
  descriptionFontStyle?: string
  mobileDescriptionFontStyle?: string
  descriptionFontWeight?: string
  mobileDescriptionFontWeight?: string
  descriptionLineHeight: number
  mobileDescriptionLineHeight?: number
  descriptionColor: string
  mobileDescriptionColor?: string
}

const ShippingSummary = ({
  productPricing,
  selectedShipping,
  bumpOfferPricing,
  locale,
  amountFontSize,
  mobileAmountFontSize,
  amountFontStyle,
  mobileAmountFontStyle,
  amountFontWeight,
  mobileAmountFontWeight,
  amountFontFamily,
  mobileAmountFontFamily,
  amountLineHeight,
  mobileAmountLineHeight,
  amountColor,
  mobileAmountColor,
  descriptionFontSize,
  mobileDescriptionFontSize,
  descriptionFontStyle,
  mobileDescriptionFontStyle,
  descriptionFontWeight,
  mobileDescriptionFontWeight,
  descriptionFontFamily,
  mobileDescriptionFontFamily,
  descriptionLineHeight,
  mobileDescriptionLineHeight,
  descriptionColor,
  mobileDescriptionColor,
}: ShippingSummaryProps) => {
  const { t } = useTranslation(['common', 'publisher'])

  const shippingTax =
    selectedShipping.price * ((productPricing?.taxPercent ?? 0) / 100)

  return (
    <>
      <DividerUi />
      <PricePlanUi>
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
            data-test-id={`offer-price-shipping-summary-net-${selectedShipping.conditionId}`}
          >
            {t('components.offer_price.shipping')}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {selectedShipping.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(selectedShipping.price),
                selectedShipping.currency,
                locale,
              )}
            </>
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
        {shippingTax > 0 && (
          <PricePlanItemWithoutLeftOffsetUi>
            <PricePlanDescriptionUi
              fontSize={descriptionFontSize}
              mobileFontSize={mobileDescriptionFontSize}
              fontStyle={descriptionFontStyle}
              mobileFontStyle={mobileDescriptionFontStyle}
              fontWeight={descriptionFontWeight}
              mobileFontWeight={mobileDescriptionFontWeight}
              fontFamily={descriptionFontFamily}
              mobileFontFamily={mobileDescriptionFontFamily}
              lineHeight={descriptionLineHeight}
              mobileLineHeight={mobileDescriptionLineHeight}
              color={descriptionColor}
              mobileColor={mobileDescriptionColor}
              data-test-id={`offer-price-shipping-summary-tax-${selectedShipping.conditionId}`}
            >
              {`${t('components.offer_price.vat')} (${
                productPricing?.taxPercent
              }%)`}
            </PricePlanDescriptionUi>
            <PricePlanAmountUi
              fontSize={amountFontSize}
              mobileFontSize={mobileAmountFontSize}
              fontStyle={amountFontStyle}
              mobileFontStyle={mobileAmountFontStyle}
              fontWeight={amountFontWeight}
              mobileFontWeight={mobileAmountFontWeight}
              fontFamily={amountFontFamily}
              mobileFontFamily={mobileAmountFontFamily}
              lineHeight={amountLineHeight}
              mobileLineHeight={mobileAmountLineHeight}
              color={amountColor}
              mobileColor={mobileAmountColor}
            >
              <>
                <CurrencyBadgeUi
                  color={amountColor}
                  mobileColor={mobileAmountColor}
                >
                  {selectedShipping.currency}
                </CurrencyBadgeUi>

                {getReadablePrice(
                  centsToEuro(shippingTax),
                  selectedShipping.currency,
                  locale,
                )}
              </>
            </PricePlanAmountUi>
          </PricePlanItemWithoutLeftOffsetUi>
        )}
        <DividerUi />
        <PricePlanItemWithoutLeftOffsetUi>
          <PricePlanDescriptionUi
            fontSize={descriptionFontSize}
            mobileFontSize={mobileDescriptionFontSize}
            fontStyle={descriptionFontStyle}
            mobileFontStyle={mobileDescriptionFontStyle}
            fontWeight={descriptionFontWeight}
            mobileFontWeight={mobileDescriptionFontWeight}
            fontFamily={descriptionFontFamily}
            mobileFontFamily={mobileDescriptionFontFamily}
            lineHeight={descriptionLineHeight}
            mobileLineHeight={mobileDescriptionLineHeight}
            color={descriptionColor}
            mobileColor={mobileDescriptionColor}
            data-test-id={`offer-price-shipping-summary-total-${selectedShipping.conditionId}`}
          >
            {t(
              'components.offer_price.one_shots_summary.total_amount_with_tax_and_shipping',
            )}
          </PricePlanDescriptionUi>
          <PricePlanAmountUi
            fontSize={amountFontSize}
            mobileFontSize={mobileAmountFontSize}
            fontStyle={amountFontStyle}
            mobileFontStyle={mobileAmountFontStyle}
            fontWeight={amountFontWeight}
            mobileFontWeight={mobileAmountFontWeight}
            fontFamily={amountFontFamily}
            mobileFontFamily={mobileAmountFontFamily}
            lineHeight={amountLineHeight}
            mobileLineHeight={mobileAmountLineHeight}
            color={amountColor}
            mobileColor={mobileAmountColor}
          >
            <>
              <CurrencyBadgeUi
                color={amountColor}
                mobileColor={mobileAmountColor}
              >
                {selectedShipping.currency}
              </CurrencyBadgeUi>

              {getReadablePrice(
                centsToEuro(
                  selectedShipping.price +
                    shippingTax +
                    (productPricing?.grossAmount ?? 0) +
                    (bumpOfferPricing?.grossAmount ?? 0),
                ),
                selectedShipping.currency,
                locale,
              )}
            </>
          </PricePlanAmountUi>
        </PricePlanItemWithoutLeftOffsetUi>
      </PricePlanUi>
    </>
  )
}

export default ShippingSummary
