import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'

export default function getPaymentMethodName(paymentMethod: PaymentMethodEnum) {
  switch (paymentMethod) {
    case PaymentMethodEnum.StripeBancontact:
      return 'Bancontact'
    case PaymentMethodEnum.StripeCard:
      return 'Credit Cards'
    case PaymentMethodEnum.StripeIDeal:
      return 'iDeal'
    case PaymentMethodEnum.PaypalSoap:
    case PaymentMethodEnum.PaypalRest:
      return 'PayPal'
    case PaymentMethodEnum.DlocalCard:
      return 'Credit or debit card (Dlocal)'
    case PaymentMethodEnum.DlocalBoleto:
      return 'Boleto (Dlocal)'
    case PaymentMethodEnum.DlocalPix:
      return 'Pix (Dlocal)'
    case PaymentMethodEnum.MercadoPagoCard:
      return 'Credit or debit card (Mercadopago)'
    case PaymentMethodEnum.MercadoPagoPix:
    case PaymentMethodEnum.MercadoPagoBrPix:
      return 'Pix (Mercadopago)'
    case PaymentMethodEnum.MercadoPagoBoleto:
    case PaymentMethodEnum.MercadoPagoBrBoleto:
      return 'Boleto (Mercadopago)'
    case PaymentMethodEnum.MercadoPagoCoCard:
    case PaymentMethodEnum.MercadoPagoMxCard:
    case PaymentMethodEnum.MercadoPagoArCard:
    case PaymentMethodEnum.MercadoPagoClCard:
    case PaymentMethodEnum.MercadoPagoBrCard:
      return 'Credit or debit card (Mercadopago)'
    case PaymentMethodEnum.RazorpayCard:
      return 'Razorpay'
    case PaymentMethodEnum.Flutterwave:
      return 'Flutterwave'
    case PaymentMethodEnum.Mollie:
      return 'Mollie'
    case PaymentMethodEnum.Gocardless:
      return 'Direct Debit'
    case PaymentMethodEnum.XenditIdCard:
    case PaymentMethodEnum.XenditPhCard:
      return 'Xendit'
    case PaymentMethodEnum.PaystackGhCard:
    case PaymentMethodEnum.PaystackKeCard:
    case PaymentMethodEnum.PaystackNgCard:
    case PaymentMethodEnum.PaystackZaCard:
      return 'Credit or debit card (Paystack)'
    case PaymentMethodEnum.SecureAndPay:
        return 'Secure and pay'
    default:
      throw new Error(`Payment method not found ${paymentMethod}`)
  }
}
